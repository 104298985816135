<template>
    <div class="page_section" v-if="train.bySlug.id">
        <MobileTitle :title="train.bySlug.title"/>
        <ContentElements v-for="(element, index) in train.bySlug.content_elements" :content="element" :key="index"/>
    </div>
</template>

<script>
    import {mapState} from 'vuex';
    import ContentElements from "../_content_elements/ContentElements";
    import MobileTitle from "../_common/MobileTitle";

    export default {
        name: "Train",
        components: {
            ContentElements,
            MobileTitle
        },
        computed: {
            ...mapState([
                'train',
            ])
        },
        created() {
            if (this.$route.params.slug) {
                this.$store.dispatch('train/getBySlug', this.$route.params.slug).then(() => {
                }, (error) => {
                    if (error.response.status === 404) {
                        this.$router.replace({
                            name: '404', params: {
                                locale: this.$route.params.locale ? this.$route.params.locale : null
                            }
                        });
                    }
                });
            }
        },
        beforeDestroy() {
            this.$store.commit('train/setBySlug', {});
        }
    }
</script>
